import React from 'react'
import { Link } from 'react-router-dom'
import * as routes from '../../../routes'
import MainCentered from '../../layout/MainCentered'
import UnauthorizedFormWrapper from '../../layout/UnauthorizedFormWrapper'

const LoginPage = () => (
    <MainCentered>
        <UnauthorizedFormWrapper>
            <h3 className="font-black text-3xl text-center mb-4">Login</h3>
            <div className="text-center">
                <p>
                    {/* eslint-disable-next-line max-len */}
                    Gunvor Trading is a secure trading environment. Please confirm the identity of this device.
                </p>
                <p>
                    Choose how you want to receive your login code.
                </p>
            </div>
            <div className="flex justify-center">
                <Link className="linkButton btn-primary mr-4" to={routes.loginPhone()}>
                    Phone
                </Link>
                <Link className="linkButton btn-primary" to={routes.loginEmail()}>
                    Email
                </Link>
            </div>
        </UnauthorizedFormWrapper>
    </MainCentered>
)

export default LoginPage
