import React from 'react'
import {
    SIDE_BUY,
} from '../../../../util/constants'
import AssetCardPrice from './AssetCardPrice'
import AssetCardAction from './AssetCardAction'
import AssetCardSelect from './AssetCardSelect'
import AssetCardContainer from './AssetCardContainer'

const WIDTH = 64

const AssetCard = ({
    marketData,
    name,
    canBuy,
    unitCode,
    currencyCode,
    currencySymbol,
    availableProductVariations,
    onChangeProductVariation,
    onClickBuy,
    productVariationId,
}) => (
    <AssetCardContainer>
        <div className="flex justify-between items-center mb-3">
            <h3 className="font-black text-3xl text-primary">
                {name}
            </h3>
            <div>
                <AssetCardSelect
                    className="mr-4"
                    value={productVariationId}
                    onChange={onChangeProductVariation}
                >
                    {availableProductVariations.map((productVariation) => (
                        <option
                            key={productVariation.id}
                            value={productVariation.id}
                        >
                            {productVariation.name}
                            {' '}
                            /
                            {' '}
                            {productVariation.currencyCode}
                            {' '}
                            /
                            {' '}
                            {productVariation.unitCode}
                        </option>
                    ))}
                </AssetCardSelect>
            </div>
        </div>
        <div className="flex flex-1 flex-col sm:flex-row">
            <div className="flex-1">
                <AssetCardPrice
                    side={SIDE_BUY}
                    code={unitCode}
                    currencySymbol={currencySymbol}
                    spotPrice={marketData.buyPrice}
                    previousSpotPrice={marketData.previousBuyPrice}
                    currencyCode={currencyCode}
                />
                <AssetCardAction
                    isDisabled={!canBuy}
                    actionText="BUY"
                    onClick={onClickBuy}
                />
            </div>
        </div>
    </AssetCardContainer>
)

AssetCard.WIDTH = WIDTH

export default AssetCard
