import React from 'react'
import clsx from 'clsx'

const Input = ({
    reference,
    type = 'text',
    className,
    label,
    infoMessage,
    successMessage,
    warningMessage,
    errorMessage,
    ...props
}) => {
    const hasLabel = typeof label !== 'undefined'
    const hasInfo = typeof infoMessage !== 'undefined' || infoMessage === null
    const hasSuccess = typeof successMessage !== 'undefined' || errorMessage === null
    const hasWarning = typeof warningMessage !== 'undefined' || errorMessage === null
    const hasError = typeof errorMessage !== 'undefined' || errorMessage === null
    const hasMessage = hasInfo || hasSuccess || hasWarning || hasError

    return (
        <>
            {hasLabel && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>{label}</label>
            )}
            <input
                {...props}
                ref={reference}
                type={type}
                className={
                    clsx(
                        'mb-4',
                        hasSuccess && 'border-green-500',
                        hasWarning && 'border-yellow-500',
                        hasError && 'border-red-500',
                        className,
                    )
                }
            />
            {hasMessage && (
                <small
                    className={clsx(
                        hasInfo && 'text-blue-500',
                        hasSuccess && 'text-green-500',
                        hasWarning && 'text-yellow-500',
                        hasError && 'text-red-500',
                        className,
                    )}
                >
                    <i>{infoMessage || successMessage || warningMessage || errorMessage}</i>
                </small>
            )}
        </>
    )
}

export default Input
