import React from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'

const AssetCardContainer = ({
    className,
    children,
    ...props
}) => (
    <motion.div
        className={
            clsx(
                'relative overflow-visible rounded-md p-6 mb-12 w-full md:w-[500px] shadow-md hover:shadow-lg border-1 border-gray-200',
                className,
            )
        }
        whileHover={{
            scale: 1.02,
        }}
        {...props}
    >
        {children}
    </motion.div>
)

export default AssetCardContainer
