/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import InputGroup from '../../util/InputGroup'
import Button from '../../util/Button'
import FormPinInput from '../../util/FormPinInput'
import Input from '../../util/Input'
import SettingsFormFields from '../settings/SettingsFormFields'

const OnboardingForm = ({
    formState,
    isSubmitting,
    onSubmit,
}) => {
    const {
        getNativeInputProps,
        getInputProps,
        getErrorMessages,
    } = formState
    const {
        REACT_APP_TERMS_CONDITIONS: termsAndConditions,
        REACT_APP_PRIVACY_POLICY: privacyPolicy,
    } = process.env
    return (
        <form className="form" onSubmit={onSubmit}>
            <div className="text-center">
                <h3>Onboarding</h3>
            </div>
            <p className="text-primary font-normal">Please choose your personal pin code.</p>
            <div className="mb-4">
                <InputGroup
                    errorMessage={getErrorMessages('pin')}
                    label="Personal pin code"
                >
                    <FormPinInput
                        {...getInputProps('pin')}
                        focus
                    />
                </InputGroup>
            </div>
            <div className="mb-4">
                <InputGroup
                    errorMessage={getErrorMessages('repeatPin')}
                    label="Repeat personal pin code"
                >
                    <FormPinInput
                        {...getInputProps('repeatPin')}
                    />
                </InputGroup>
            </div>
            <div className="mt-8">
                <p className="text-primary font-normal">Please fill in the information below to start trading.</p>
                <SettingsFormFields
                    formState={formState}
                />
            </div>
            <div className="mb-4">
                <InputGroup errorMessage={getErrorMessages('termsAndConditions')}>
                    <label className="flex items-center">
                        <Input
                            {...getNativeInputProps('termsAndConditions')}
                            type="checkbox"
                            className="mr-4 mb-0 rouned-default"
                        />
                        I have read the&nbsp;
                        <a className="hyperlink" href={termsAndConditions} target="_blank" rel="noopener noreferrer">
                            Terms & Conditions
                        </a>
                    </label>
                </InputGroup>
            </div>
            <div className="mb-8">
                <InputGroup errorMessage={getErrorMessages('privacyPolicy')}>
                    <label className="flex items-center">
                        <Input
                            {...getNativeInputProps('privacyPolicy')}
                            type="checkbox"
                            className="mr-4 mb-0 rouned-default"
                        />
                        <span>I have read the&nbsp;</span>
                        <a className="hyperlink" href={privacyPolicy} rel="noopener noreferrer" target="_blank">
                            Privacy Policy
                        </a>
                    </label>
                </InputGroup>
            </div>
            <Button
                className="btn-primary w-full"
                loading={isSubmitting}
                onClick={onSubmit}
            >
                Continue
            </Button>
        </form>
    )
}

export default OnboardingForm
