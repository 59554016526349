import React from 'react'
import roundTo from 'round-to'
import clsx from 'clsx'
import { getRoundingForUnit } from '@gunvor-trading/shared/utils/units'
import * as constants from '../../../../util/constants'
import Triangle from '../../../util/Triangle'

const {
    UNIT_CODE_GRAM: G,
    UNIT_CODE_KILOGRAM: KG,
    UNIT_CODE_CUBM: CUBM,
    UNIT_CODE_METRIC_TON: TON,
    UNIT_CODE_LITER: L,
} = constants

const formatQuantity = (quantity, unitCode) => {
    // By multiplying, we make sure quantity's type is number
    const quantityNumber = quantity * 1
    const rounding = getRoundingForUnit(unitCode)
    return roundTo(quantityNumber, rounding)
}

const getStep = (unitCode) => {
    switch (unitCode) {
        case KG:
        case G:
        case L:
            return `1e-${0}`
        case CUBM:
        case TON:
            return `1e-${3}`
        default:
            return 1
    }
}

const QuantityInput = ({
    min,
    max,
    onChange,
    value,
    unitCode,
    assetName,
    availableUnits,
    onChangeUnit,
}) => {
    const step = getStep(unitCode)
    const hasError = value < min || value > max
    return (
        <>
            <div className="mr-3 inline-flex">
                <input
                    step={step}
                    type="number"
                    value={value}
                    onChange={(e) => {
                        const targetValue = e.target.value
                        const newValue = formatQuantity(targetValue, unitCode)
                        if (targetValue === '') {
                            return onChange('')
                        }
                        return onChange(newValue)
                    }}
                    className={
                        clsx(
                            'rounded-default rounded-r-none border-r-0 w-[100px]',
                            hasError && 'border-red-500 border-r-1',
                        )
                    }
                />

                <div className="relative inline-block">
                    <div className="absolute flex h-full items-center top-0 right-2.5">
                        <Triangle size={8} />
                    </div>
                    <select
                        className="rounded-default rounded-l-none min-w-[60px]"
                        value={unitCode}
                        onChange={(e) => onChangeUnit(e.target.value)}
                    >
                        {availableUnits.map(({ code }) => (
                            <option
                                key={code}
                                value={code}
                            >
                                {code}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <span>{assetName}</span>
        </>
    )
}

export default QuantityInput
