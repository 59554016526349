import { ROUND_UP, ROUND_DOWN } from '@gunvor-trading/shared/utils/constants'
import { getTradeLimit } from '@gunvor-trading/shared/utils/units'
import * as validation from '../index'

export const isValidQuantity = (quantity, min, max) => (
    validation.isNumber(quantity)
        && validation.isGreaterOrEqualThan(min)(quantity)
        && validation.isLessOrEqualThan(max)(quantity)
)

export const isValidNewOrderForm = (
    { quantity },
    minTradeLimit,
    maxTradeLimit,
    tradeLimitUnitCode,
    quantityUnitCode,
    density,
) => (
    isValidQuantity(
        quantity,
        getTradeLimit(
            minTradeLimit,
            tradeLimitUnitCode,
            quantityUnitCode,
            density,
            { direction: ROUND_UP },
        ),
        getTradeLimit(
            maxTradeLimit,
            tradeLimitUnitCode,
            quantityUnitCode,
            density,
            { direction: ROUND_DOWN },
        ),
    )
)
