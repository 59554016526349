import React from 'react'
import MainCentered from '../../layout/MainCentered'
import UnauthorizedFormWrapper from '../../layout/UnauthorizedFormWrapper'
import LoginPasswordForm from './LoginPasswordForm'

const LoginPasswordPage = ({
    formState,
    isSubmitting,
    email,
    phone,
    emailWasSent,
    onSubmit,
}) => (
    <MainCentered>
        <UnauthorizedFormWrapper>
            <div className="text-center">
                <h3>Login Password</h3>
                {emailWasSent
                    ? (
                        <p> An email with your one time password was sent to
                            {' '}<strong>{email}</strong>
                            . Please check your inbox and enter your one time password.
                        </p>
                    )
                    : (
                        <p>
                            An sms with your one time password was sent to
                            {' '}<strong>{phone}</strong>
                            . Please check your recent messages and enter your one time password.
                        </p>
                    )}
            </div>
            <LoginPasswordForm
                formState={formState}
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
            />
        </UnauthorizedFormWrapper>
    </MainCentered>
)

export default LoginPasswordPage
