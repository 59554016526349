import URLSearchParams from 'url-search-params'

export const where = (...keys) => (...values) => (obj) => keys.every((key) => (key.includes('!') ? !values.includes(obj[key.replace('!', '')]) : values.includes(obj[key])))

export const whereId = where('id')

export const delay = (time, value) => new Promise((resolve) => {
    setTimeout(resolve, time, value)
})

export const readQueryString = (string) => {
    const searchParams = new URLSearchParams(string)
    const entries = Array.from(searchParams.entries())
    return entries.reduce((obj, entry) => {
        const key = entry[0]
        const value = entry[1]
        const newObj = { ...obj }
        newObj[key] = value
        return newObj
    }, {})
}

export const toQueryString = (obj = {}) => {
    const searchParams = new URLSearchParams()
    Object.keys(obj).forEach((key) => searchParams.append(key, obj[key]))
    return searchParams.toString()
}

export const flatten = (a, b) => [...a, ...b]

export const combineRefs = (refs = []) => (el) => {
    refs.forEach((ref) => {
        if (typeof ref === 'function') {
            ref(el)
        } else {
            // eslint-disable-next-line no-param-reassign
            ref.current = el
        }
    })
}
