import { useQuery, gql } from '@apollo/client'

const TRADE_PAGE_QUERY = gql`
    query tradePageQuery {
        myCustomerAssets {
            suspended
            canBuy
            asset {
                id
                name
                code
                suspended
                density
            }
            productVariations {
                id
                name
                currencyCode
                unitCode
            }
            currencies {
                name
                code
                realSymbol
            }
            units {
                name
                code
            }
        }
    }
`

const useFetchTradePageData = () => {
    const {
        error,
        loading,
        data = {},
    } = useQuery(TRADE_PAGE_QUERY)
    const { myCustomerAssets = {} } = data

    return {
        error,
        isFetching: loading,
        myCustomerAssets,
    }
}

export default useFetchTradePageData
