import React from 'react'
import Logo from '../util/Logo'

const UnauthorizedFormWrapper = ({
    children,
}) => {
    const {
        REACT_APP_TERMS_CONDITIONS: termsAndConditions,
        REACT_APP_PRIVACY_POLICY: privacyPolicy,
    } = process.env
    return (
        <div className="mt-[-75px] w-[375px] min-h-[450px]">
            <div className="flex items-center justify-center mb-8">
                <Logo height={100} lassName="mb-4" />
            </div>
            {children}
            <div className="flex mt-8">
                <div className="flex flex-1 justify-center">
                    <a className="hyperlink" href={termsAndConditions} target="_blank" rel="noopener noreferrer">
                        Terms & Conditions
                    </a>
                </div>
                <div className="flex flex-1 justify-center">
                    <a className="hyperlink" href={privacyPolicy} rel="noopener noreferrer" target="_blank">
                        Privacy Policy
                    </a>
                </div>
            </div>
        </div>
    )
}

export default UnauthorizedFormWrapper
