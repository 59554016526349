import { useQuery, gql } from '@apollo/client'

const MARKET_DATA_QUERY = gql`
    query myCustomerMarketData(
        $assetCode: String!,
        $productVariationId: Int!,
    ) {
        myCustomerMarketData(
            assetCode: $assetCode,
            productVariationId: $productVariationId,
        ) {
            status
            buyPrice
            previousBuyPrice
            currencyConversion
            dailyTradeLimit
        }
  }
`

const useFetchAssetCardData = (
    assetCode,
    productVariationId,
    pollInterval,
) => {
    const assetCardQuery = MARKET_DATA_QUERY
    const {
        error,
        loading,
        data = {},
    } = useQuery(assetCardQuery, {
        variables: {
            assetCode,
            productVariationId,
        },
        pollInterval,
    })

    const { myCustomerMarketData = {} } = data

    return {
        error,
        loading,
        myCustomerMarketData,
    }
}

export default useFetchAssetCardData
