import React from 'react'
import Page from '../../layout/Page'
import Navbar from '../../layout/Navbar'
import Main from '../../layout/Main'
import PageTitle from '../../layout/PageTitle'
import Spinner from '../../util/Spinner'
import Container from '../../util/Container'
import AssetCardWithState from './assetcard/AssetCardWithState'
import AssetCardSuspended from './assetcard/AssetCardSuspended'

const TradePage = ({
    isFetching,
    myCustomerAssets,
}) => (
    <Page>
        <Navbar activeMenuItem="trade" />
        <Main>
            <Container className="flex flex-col items-center">
                <PageTitle
                    title="Tradable Assets"
                    text="Below you find the assets that you are eligible to trade"
                />
                {isFetching && <Spinner />}
                {!isFetching && myCustomerAssets.map(({
                    asset,
                    canBuy,
                    suspended,
                    currencies,
                    units,
                    productVariations,
                }) => {
                    if (suspended || asset.suspended || productVariations.length === 0) {
                        return (
                            <AssetCardSuspended
                                key={asset.code}
                                assetName={asset.name}
                            />
                        )
                    }

                    return (
                        <AssetCardWithState
                            asset={asset}
                            key={asset.code}
                            canBuy={canBuy}
                            availableCurrencies={currencies}
                            availableUnits={units}
                            availableProductVariations={productVariations}
                        />
                    )
                })}
            </Container>
        </Main>
    </Page>
)

export default TradePage
