import { Component } from 'react'

const propsToInitialState = (props) => {
    const {
        initialState,
        initialValues,
        initialErrors,
    } = props
    return ({
        isLoading: false,
        isValid: false,
        values: initialValues,
        errors: typeof initialErrors !== 'undefined'
            ? initialErrors
            : Object
                .keys(initialValues || {})
                .reduce((acc, curr) => {
                    acc[curr] = null
                    return acc
                }, {}),
        ...initialState,
    })
}

class FormState extends Component {
    constructor(props) {
        super(props)

        this.isPristine = true
        this.state = propsToInitialState(props)

        this.publicSetState = (...args) => this.setState(...args)

        this.resetForm = this.resetForm.bind(this)
        this.mergeValues = this.mergeValues.bind(this)
        this.updateErrors = this.updateErrors.bind(this)
        this.checkValidation = this.checkValidation.bind(this)

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeAsValue = this.handleChangeAsValue.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
    }

    componentDidMount() {
        const { didMount } = this.props
        if (typeof didMount === 'function') {
            didMount(this.getChildProps())
        }
    }

    componentWillUnmount() {
        const { willUnmount } = this.props
        if (typeof willUnmount === 'function') {
            willUnmount(this.getChildProps())
        }
    }

    handleChange(e) {
        const {
            name, type, value, checked, files,
        } = e.target
        let finalValue = value
        if (type === 'checkbox') {
            finalValue = checked
        } else if (type === 'file') {
            [finalValue] = files
        }
        this.setState((state) => ({
            values: {
                ...state.values,
                [name]: finalValue,
            },
        }))
        if (!this.isPristine) {
            this.updateErrors()
        }
    }

    handleChangeAsValue(name, value) {
        this.setState((state) => ({
            values: {
                ...state.values,
                [name]: value,
            },
        }))
        if (!this.isPristine) {
            this.updateErrors()
        }
    }

    handleCancel() {
        this.resetForm()
        const { onCancel } = this.props
        if (typeof onCancel !== 'undefined') {
            onCancel()
        }
    }

    getChildProps() {
        const { errors, values } = this.state
        return {
            state: this.state,
            errors,
            values,
            setState: this.publicSetState,
            resetForm: this.resetForm,
            mergeValues: this.mergeValues,
            checkValidation: this.checkValidation,
            updateErrors: this.updateErrors,
            handleChange: this.handleChange,
            handleChangeAsValue: this.handleChangeAsValue,
            handleCancel: this.handleCancel,
        }
    }

    getFormErrors(state) {
        const { getFormErrors } = this.props
        return typeof getFormErrors === 'function'
            ? getFormErrors(state.values)
            : {}
    }

    resetForm() {
        this.isPristine = true
        this.setState(propsToInitialState(this.props))
    }

    isValidForm() {
        const { isValidForm } = this.props
        const { values } = this.state
        return typeof isValidForm === 'function'
            ? isValidForm(values)
            : true
    }

    updateErrors(serverErrors = {}) {
        this.setState((state) => ({
            errors: {
                ...state.errors,
                ...this.getFormErrors(state),
                ...serverErrors,
            },
        }))
    }

    mergeValues(values) {
        this.setState((state) => ({
            values: {
                ...state.values,
                ...values,
            },
        }))
    }

    checkValidation() {
        this.isPristine = false
        const isValid = this.isValidForm()
        if (!isValid) {
            this.invalidFormWasSubmitted()
        } else {
            this.validFormWasSubmitted()
        }
        return isValid
    }

    invalidFormWasSubmitted() {
        this.updateErrors()
        const { onInvalidFormWasSubmitted } = this.props
        if (typeof onInvalidFormWasSubmitted === 'function') {
            onInvalidFormWasSubmitted()
        }
    }

    validFormWasSubmitted() {
        const { onValidFormWasSubmitted } = this.props
        if (typeof onValidFormWasSubmitted === 'function') {
            onValidFormWasSubmitted()
        }
    }

    render() {
        const { debug, children } = this.props
        if (debug) {
            // eslint-disable-next-line no-console
            console.log(this.state)
        }
        return children(this.getChildProps())
    }
}

export default FormState
