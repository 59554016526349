import React from 'react'
import { ROUND_DOWN, ROUND_UP } from '@gunvor-trading/shared/utils/constants'
import { getTradeLimit } from '@gunvor-trading/shared/utils/units'
import { currencyCodeToSymbol } from '../../../../util/currency'
import Alert from '../../../util/Alert'
import CurrencyValue from '../../../util/CurrencyValue'
import Button from '../../../util/Button'
import AssetCardPrice from '../assetcard/AssetCardPrice'
import NewOrderTable from './NewOrderTable'
import useAppState from '../../../hooks/useAppState'

const NewOrderDetails = ({
    side,
    asset,
    orderRequest,
    quantity,
    quantityUnitCode,
    onChangeQuantity,
    onNext,
    isValidQuantity,
    maxTradeLimit,
    minTradeLimit,
    availableUnits,
    onChangeUnit,
}) => {
    const {
        spotPrice, currencyCode, unitCode,
    } = orderRequest
    const currencySymbol = currencyCodeToSymbol(currencyCode)
    const { currentUser } = useAppState()
    const { tradeLimitUnitCode } = currentUser.customer
    const minimum = getTradeLimit(
        minTradeLimit,
        tradeLimitUnitCode,
        quantityUnitCode,
        asset.density,
        { direction: ROUND_UP },
    )
    const maximum = getTradeLimit(
        maxTradeLimit,
        tradeLimitUnitCode,
        quantityUnitCode,
        asset.density,
        { direction: ROUND_DOWN },
    )

    return (
        <>
            <AssetCardPrice
                actionText="BUYING AT"
                code={unitCode}
                currencySymbol={currencySymbol}
                spotPrice={spotPrice}
                currencyCode={currencyCode}
            />
            <NewOrderTable
                spotPrice={spotPrice}
                quantity={quantity}
                transactionFee="none"
                taxRate="none"
                onChangeQuantity={onChangeQuantity}
                assetSymbol={asset.code}
                unitCode={quantityUnitCode}
                currencySymbol={currencySymbol}
                assetName={asset.name.toLowerCase()}
                minTradeLimit={minimum}
                maxTradeLimit={maximum}
                availableUnits={availableUnits}
                onChangeUnit={onChangeUnit}
            />
            {isValidQuantity
                ? (
                    <Alert isBlue className="text-center">
                        <p>You
                            {' buy '}
                            <strong>{quantity || 0}{quantityUnitCode}</strong>
                            {' '}of{' '}
                            {asset.name.toLowerCase() }
                            {' '}at{' '}
                            <strong>
                                <CurrencyValue value={spotPrice} currencyCode={`${currencyCode}/${unitCode}`} />
                            </strong>.
                        </p>
                    </Alert>
                )
                : (
                    <Alert isRed className="text-center">
                        <p>
                            {`Select a quantity between ${minimum} ${quantityUnitCode} and ${maximum} ${quantityUnitCode}`}
                        </p>
                    </Alert>
                )}
            <Button
                disabled={!isValidQuantity}
                className="btn-primary w-full"
                onClick={onNext}
            >
                {side}
            </Button>
        </>
    )
}

export default NewOrderDetails
