import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import EnvironmentBar from '@wappla/show-env-react'
import * as routes from '../../routes'
import Logo from '../util/Logo'
import Container from '../util/Container'
import FeatherIcon from '../util/FeatherIcon'
import NavbarMenu from './NavbarMenu'

const Navbar = ({
    settingsIsActive,
    activeMenuItem,
}) => (
    <nav className="fixed mb-8 top-0 left-0 w-full z-navBar bg-white border-b-1 border-gray-200">
        {process.env.REACT_APP_ENV !== 'production' && (
            <EnvironmentBar env={process.env.REACT_APP_ENV} />
        )}
        <Container className="flex items-center justify-between">
            <div className="flex flex-1 justify-start">
                <Logo
                    height={32}
                />
            </div>
            <NavbarMenu
                className="flex flex-1 justify-center"
                activeMenuItem={activeMenuItem}
            />
            <Link
                className={clsx(
                    'flex flex-1 justify-end',
                    settingsIsActive && 'text-primary',
                )}
                to={routes.settings()}
            >
                <FeatherIcon name="user" />
            </Link>
        </Container>
    </nav>
)

export default Navbar
