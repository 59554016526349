import React from 'react'
import { Link } from 'react-router-dom'
import { milligramToUnit } from '@gunvor-trading/shared/utils/units'
import * as routes from '../../../../routes'
import { formatCurrency } from '../../../../util/currency'
import FeatherIcon from '../../../util/FeatherIcon'
import FocusDidMount from '../../../util/FocusDidMount'
import NewOrderContainer from './NewOrderContainer'

const NewOrderSuccess = ({
    order,
    onDone,
}) => {
    const {
        side,
        quantityUnitCode,
        quantity,
        currencyCode,
        price,
        priceUnitCode,
        assetCode,
        density,
    } = order
    const visualQuantity = milligramToUnit(quantity, quantityUnitCode, density)
    const visualPrice = formatCurrency(price, currencyCode)

    return (
        <NewOrderContainer>
            <div className="flex items-center justify-center p-8 mb-4 h-[100px] w-[100px] bg-green-500 text-white rounded-full">
                <FeatherIcon size={56} name="check" />
            </div>
            <h3>Trade successful</h3>
            <p className="text-center">
                You successfully {side === 'BUY' ? 'bought' : 'sold'} {visualQuantity}{quantityUnitCode}{' '}
                of {assetCode} at {visualPrice} {currencyCode}/{priceUnitCode}.
                You can check your <Link className="hyperlink" to={routes.history()}>trade history</Link> to follow up on this trade.
            </p>
            <FocusDidMount>
                {(ref) => (
                    <button
                        type="button"
                        ref={ref}
                        className="btn-secondary mt-8"
                        onClick={onDone}
                    >
                        Done
                    </button>
                )}
            </FocusDidMount>
        </NewOrderContainer>
    )
}

export default NewOrderSuccess
