import { gql } from '@apollo/client'

export const DEFAULT_USER_FRAGMENT = gql`
    fragment DefaultUser on User {
        id
        email
        phone
        firstName
        lastName
        hasCompletedOnboarding
        preferredDateFormat
        preferredTimeFormat
        preferredCurrencyCode
        preferredCurrency {
            code
            name
            symbol
            realSymbol
        }
        preferredUnitCode
        preferredUnit {
            code
            name
        }
        customer {
            id
            name
            minTradeLimit
            maxTradeLimit
            tradeLimit {
                minTradeLimit
                maxTradeLimit
            }
            dailyTradeLimit
            tradeLimitUnitCode
        }
    }
`

export const INITIAL_DATA_QUERY = gql`
    query initialData {
        me {
            ...DefaultUser
        }
    }
    ${DEFAULT_USER_FRAGMENT}
`

export default async function getInitialData(apolloClient) {
    const query = INITIAL_DATA_QUERY
    const response = await apolloClient.query({ query })
    return response.data
}
