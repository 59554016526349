import React, { useState } from 'react'
import { where, whereId } from '../../../../util'
import useFetchAssetCardData from './useFetchAssetCardData'
import AssetCardLoading from './AssetCardLoading'
import AssetCardError from './AssetCardError'
import AssetCardSuspended from './AssetCardSuspended'
import AssetCardUnavailable from './AssetCardUnavailable'
import AssetCardWithModal from './AssetCardWithModal'
import useAppState from '../../../hooks/useAppState'

const POLL_FREQUENCY = 1000 * 3 // 3 sec

const STATUS_UNAVAILABLE = 'UNAVAILABLE'
const STATUS_SUSPENDED = 'SUSPENDED'

const whereCode = where('code')

const AssetCardWithState = ({
    asset,
    canBuy,
    availableCurrencies,
    availableUnits,
    availableProductVariations,
}) => {
    const { currentUser } = useAppState()
    const firstProductVariation = availableProductVariations[0]
    const [isShowingKarats, setIsShowingKarats] = useState(false)
    const [productVariationId, setProductVariationId] = useState(firstProductVariation.id)
    const { unitCode, currencyCode } = availableProductVariations.find(whereId(productVariationId))
    const {
        error,
        loading,
        myCustomerMarketData,
    } = useFetchAssetCardData(
        asset.code,
        productVariationId,
        POLL_FREQUENCY,
    )

    if (loading) {
        return <AssetCardLoading />
    }
    if (typeof error !== 'undefined') {
        return <AssetCardError />
    }
    if (myCustomerMarketData.status === STATUS_SUSPENDED) {
        return <AssetCardSuspended assetName={asset.name} />
    }
    if (myCustomerMarketData.status === STATUS_UNAVAILABLE) {
        return <AssetCardUnavailable assetName={asset.name} />
    }
    const selectedCurrency = availableCurrencies.find(whereCode(currencyCode))
    const handleChangeProductVariation = (value) => {
        setProductVariationId(parseInt(value, 10))
    }

    const { customer } = currentUser
    const { minTradeLimit } = customer
    const { dailyTradeLimit: maxTradeLimit } = myCustomerMarketData
    return (
        <AssetCardWithModal
            asset={asset}
            canBuy={canBuy}
            marketData={myCustomerMarketData}
            unitCode={unitCode}
            currencyCode={currencyCode}
            currencySymbol={selectedCurrency.realSymbol}
            availableCurrencies={availableCurrencies}
            availableUnits={availableUnits}
            productVariationId={productVariationId}
            availableProductVariations={availableProductVariations}
            minTradeLimit={minTradeLimit}
            maxTradeLimit={maxTradeLimit}
            onChangeProductVariation={handleChangeProductVariation}
            onToggleKarats={() => setIsShowingKarats(!isShowingKarats)}
            isShowingKarats={isShowingKarats}
        />
    )
}

export default AssetCardWithState
