import { gql } from '@apollo/client'
import { createUnauthorizedApolloClient } from '../apollo/createApolloClient'

export const query = gql`
    query health {
        health {
            customerAppIsAvailable
        }
    }
`

export default async function getAppHealth() {
    const apolloClient = createUnauthorizedApolloClient()
    const { data } = await apolloClient.query({ query })
    return data
}
