import React from 'react'
import TradeLimits from './TradeLimits'
import QuantityInput from './QuantityInput'

const NewOrderTable = ({
    quantity,
    unitCode,
    assetName,
    onChangeQuantity,
    maxTradeLimit,
    minTradeLimit,
    availableUnits,
    onChangeUnit,
}) => (
    <div className="flex my-8">
        <div className="flex-1">
            <h5 className="text-primary text-lg font-black mb-4">Trade limits</h5>
            <div className="flex flex-col justify-center">
                <TradeLimits
                    max={maxTradeLimit}
                    min={minTradeLimit}
                    unitCode={unitCode}
                />
            </div>
        </div>
        <div className="flex-1">
            <h5 className="text-primary text-lg font-black mb-4">Confirm your amount</h5>
            <div className="flex items-center">
                <QuantityInput
                    min={minTradeLimit}
                    max={maxTradeLimit}
                    onChange={onChangeQuantity}
                    value={quantity}
                    unitCode={unitCode}
                    assetName={assetName}
                    availableUnits={availableUnits}
                    onChangeUnit={onChangeUnit}
                />
            </div>
        </div>
    </div>
)

export default NewOrderTable
