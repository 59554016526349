import React from 'react'
import NewOrderDetails from './NewOrderDetails'
import NewOrderHeader from './NewOrderHeader'
import NewOrderFailure from './NewOrderFailure'
import NewOrderSuccess from './NewOrderSuccess'
import NewOrderLoading from './NewOrderLoading'
import NewOrderAuthenticate from './NewOrderAuthenticate'

const NewOrderModal = ({
    sessionHasExpired,
    values,
    errors,
    maxTradeLimit,
    minTradeLimit,
    side,
    asset,
    order,
    orderRequest,
    isValidQuantity,
    isAuthenticating,
    isLoading,
    orderIsSuccess,
    errorMessage,
    onChangeQuantity,
    onRequestExpired,
    onConfirmOrder,
    onCancelAuthenticate,
    onNext,
    onDone,
    availableUnits,
    onChangeUnit,
}) => {
    if (isLoading) {
        return (
            <NewOrderLoading />
        )
    }
    if (orderIsSuccess) {
        return (
            <NewOrderSuccess
                order={order}
                onDone={onDone}
            />
        )
    }
    if (errorMessage !== null) {
        return (
            <NewOrderFailure
                onDone={onDone}
                error={errorMessage}
            />
        )
    }
    if (sessionHasExpired) {
        return (
            <NewOrderFailure
                onDone={onDone}
                error="Your session has expired."
                text="Close this window to start a new session."
            />
        )
    }
    return (
        <>
            <NewOrderHeader
                orderRequestedAt={orderRequest.requestedAt}
                onRequestExpired={onRequestExpired}
            />
            {(() => {
                if (isAuthenticating) {
                    return (
                        <NewOrderAuthenticate
                            onCancel={onCancelAuthenticate}
                            onComplete={onConfirmOrder}
                        />
                    )
                }
                return (
                    <NewOrderDetails
                        side={side}
                        asset={asset}
                        orderRequest={orderRequest}
                        quantity={values.quantity}
                        quantityUnitCode={values.quantityUnitCode}
                        error={errors.quantity}
                        onChangeQuantity={onChangeQuantity}
                        availableUnits={availableUnits}
                        onChangeUnit={onChangeUnit}
                        isValidQuantity={isValidQuantity}
                        onNext={onNext}
                        maxTradeLimit={maxTradeLimit}
                        minTradeLimit={minTradeLimit}
                    />
                )
            })()}
        </>
    )
}

export default NewOrderModal
