import React from 'react'
import * as theme from '../../../../styles/theme'
import AssetCardPlaceholder from './AssetCardPlaceholder'

const AssetCardUnavailable = ({
    assetName,
}) => (
    <AssetCardPlaceholder
        assetName={assetName}
        title="Market closed"
        text={<>The market for this asset is closed. Please do try again later.</>}
        iconName="crossCircle"
        iconInnerColor={theme.colorRed}
    />
)

export default AssetCardUnavailable
