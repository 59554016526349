import React from 'react'
import useAppState from './hooks/useAppState'
import AppFailure from './app/AppFailure'
import AppLoading from './app/AppLoading'
import AppUnavailable from './app/AppUnavailable'
import AppErrorBoundary from './app/AppErrorBoundary'
import AppRouter from './AppRouter'

const App = ({
    history,
}) => {
    const {
        isAvailable,
        isAppLoading,
        isLoadingTokens,
        isLoadingInitialData,
        isAuthenticated,
        currentUser,
        hasHealthNetworkError,
    } = useAppState()
    const isLoading = (
        isLoadingTokens
        || isLoadingInitialData
        || isAppLoading
    )

    if (hasHealthNetworkError) {
        return <AppFailure />
    }
    if (!isAvailable) {
        return <AppUnavailable />
    }

    return (
        <AppErrorBoundary>
            {isLoading
                ? (
                    <AppLoading />
                )
                : (
                    <AppRouter
                        history={history}
                        isAuthenticated={isAuthenticated}
                        currentUser={currentUser}
                    />
                )}
        </AppErrorBoundary>
    )
}

export default App
