import React, { useCallback, useState } from 'react'
import { useApolloClient, gql } from '@apollo/client'
import { unitToMilligram, getTradeLimit } from '@gunvor-trading/shared/utils/units'
import { ROUND_UP } from '@gunvor-trading/shared/utils/constants'
import { useAsyncEffect } from '@wappla/react-hooks'
import { isValidNewOrderForm } from '../../../../validation/new-order/newOrder'
import { getNewOrderFormErrors } from '../../../../validation/new-order/newOrderErrors'
import FormState from '../../../util/FormState'
import NewOrderModal from './NewOrderModal'
import { getRemainingSeconds } from './TimeRemaining'
import useAppState from '../../../hooks/useAppState'

const MAX_ORDER_REQUESTS = 5

const CREATE_ORDER_REQUEST_MUTATION = gql`
    mutation createOrderRequest($input: OrderRequestInput!) {
        createOrderRequest(input: $input) {
            orderRequest {
                id
                unitCode
                spotPrice
                currencyCode
                requestedAt
            }
        }
    }
`

const CREATE_ORDER_MUTATION = gql`
    mutation createOrder($input: OrderInput!) {
        createOrder(input: $input) {
            order {
                id
                side
                quantity
                quantityUnitCode
                price
                priceUnitCode
                currencyCode
                assetCode
                density
            }
        }
    }
`

const createOrder = async (apollo, input) => {
    const mutation = CREATE_ORDER_MUTATION
    const variables = { input }
    const response = await apollo.mutate({
        mutation,
        variables,
    })
    return response.data.createOrder.order
}

const INITIAL_STATE = {
    requestIsLoading: true,
    requestIsSuccess: false,
    requestIsFailure: false,
    orderRequest: null,
    orderRequestError: null,
    isAuthenticating: false,
    orderIsLoading: false,
    orderIsSuccess: false,
    order: null,
    orderError: null,
    requestCount: 1,
}

const NewOrderModalWithState = ({
    side,
    asset,
    initialSpotPrice,
    currencyConversion,
    unitCode,
    currencyCode,
    onToggleDisableDismiss,
    onDismiss,
    maxTradeLimit,
    minTradeLimit,
    availableUnits,
    productVariationId,
}) => {
    const apollo = useApolloClient()
    const { currentUser } = useAppState()
    const { tradeLimitUnitCode } = currentUser.customer
    const { density, id: assetId } = asset
    const orderRequestInput = {
        assetId,
        side,
        unitCode,
        currencyCode,
        productVariationId,
    }
    // TODO: split up each state entry into seperate useState hook
    const [state, setState] = useState(INITIAL_STATE)
    const [errorMessage, setErrorMessage] = useState(null)

    const createOrderRequest = async (input) => {
        try {
            const mutation = CREATE_ORDER_REQUEST_MUTATION
            const variables = { input }
            const response = await apollo.mutate({
                mutation,
                variables,
            })
            return response.data.createOrderRequest.orderRequest
        } catch (error) {
            setErrorMessage(error.message)
            return null
        }
    }

    useAsyncEffect(async () => {
        const input = {
            ...orderRequestInput,
            spotPrice: initialSpotPrice,
        }
        const orderRequest = await createOrderRequest(input)
        setState({
            ...state,
            orderRequest,
            requestIsLoading: false,
        })
    }, [])

    const getQuantity = useCallback((quantityUnitCode) => getTradeLimit(
        minTradeLimit,
        tradeLimitUnitCode,
        quantityUnitCode,
        density,
        { direction: ROUND_UP },
    ), [
        minTradeLimit,
        tradeLimitUnitCode,
        density,
    ])

    return (
        <FormState
            getFormErrors={getNewOrderFormErrors}
            initialValues={{
                quantity: getQuantity(unitCode),
                quantityUnitCode: unitCode,
            }}
        >
            {({
                values,
                errors,
                handleChangeAsValue,
            }) => {
                const { orderRequest, requestIsLoading } = state
                const { quantityUnitCode } = values
                return (
                    <NewOrderModal
                        values={values}
                        errors={errors}
                        sessionHasExpired={
                            state.requestCount >= MAX_ORDER_REQUESTS
                                || orderRequest === null
                                || typeof orderRequest === 'undefined'
                                // when the remaining seconds goes belows 0 it usually means the 'onRequestExpired' failed to fire for some reaseon
                                // We don't check on exactly 0 we add some buffer
                                || (
                                    getRemainingSeconds(orderRequest.requestedAt) <= -2
                                    && !requestIsLoading
                                )
                        }
                        maxTradeLimit={maxTradeLimit}
                        minTradeLimit={minTradeLimit}
                        side={side}
                        asset={asset}
                        order={state.order}
                        orderRequest={orderRequest}
                        availableUnits={availableUnits}
                        isValidQuantity={isValidNewOrderForm(
                            values,
                            minTradeLimit,
                            maxTradeLimit,
                            tradeLimitUnitCode,
                            quantityUnitCode,
                            density,
                        )}
                        isAuthenticating={state.isAuthenticating}
                        isLoading={
                            state.orderIsLoading
                                || requestIsLoading
                        }
                        orderIsSuccess={state.orderIsSuccess}
                        errorMessage={errorMessage}
                        onChangeQuantity={(quantity) => {
                            handleChangeAsValue('quantity', quantity)
                        }}
                        onChangeUnit={(newUnit) => {
                            handleChangeAsValue('quantityUnitCode', newUnit)
                            handleChangeAsValue('quantity', getQuantity(newUnit))
                        }}
                        onNext={() => setState({ ...state, isAuthenticating: true })}
                        onCancelAuthenticate={() => setState({ ...state, isAuthenticating: false })}
                        onDone={onDismiss}
                        onRequestExpired={async () => {
                            setState({ ...state, requestIsLoading: true })
                            const input = {
                                ...orderRequestInput,
                                spotPrice: initialSpotPrice,
                            }
                            const newOrderRequest = await createOrderRequest(input)
                            const newRequestCount = state.requestCount + 1
                            setState({
                                ...state,
                                orderRequest: newOrderRequest,
                                requestIsLoading: false,
                                isAuthenticating: false,
                                requestCount: newRequestCount,
                            })
                        }}
                        onConfirmOrder={async (pin) => {
                            onToggleDisableDismiss(true)
                            setState({ ...state, orderIsLoading: true })
                            const quantity = Math.round(unitToMilligram(
                                values.quantity * 1000,
                                quantityUnitCode,
                                density,
                            ) / 1000)
                            const orderRequestId = state.orderRequest.id
                            const input = {
                                pin,
                                quantity,
                                quantityUnitCode,
                                orderRequestId,
                                currencyConversion,
                                productVariationId,
                            }
                            try {
                                const order = await createOrder(apollo, input)

                                setState({
                                    ...state,
                                    order,
                                    orderIsLoading: false,
                                    orderIsSuccess: true,
                                })
                            } catch (error) {
                                if (error.graphQLErrors) {
                                    setErrorMessage(error.graphQLErrors[0].message)
                                }
                                setState({ ...state, orderIsLoading: false })
                            }
                            onToggleDisableDismiss(false)
                        }}

                    />
                )
            }}
        </FormState>
    )
}

export default NewOrderModalWithState
