import React from 'react'
import Spinner from '../../../util/Spinner'
import NewOrderContainer from './NewOrderContainer'

const NewOrderLoading = () => (
    <NewOrderContainer>
        <Spinner />
    </NewOrderContainer>
)

export default NewOrderLoading
