import React from 'react'
import posed from 'react-pose'
import FeatherIcon from './FeatherIcon'

const AnimatedButton = posed.button({
    pressable: true,
    init: { scale: 1 },
    press: { scale: 0.975 },
})

const Button = ({
    disabled,
    loading,
    children,
    ...props
}) => (
    <AnimatedButton
        disabled={disabled || loading}
        {...props}
    >
        { loading
            ? <FeatherIcon name="loader" className="rotating" />
            : children}
    </AnimatedButton>
)

export default Button
