import React from 'react'

const TradeLimits = ({
    max,
    min,
    unitCode,
}) => (
    <div className="flex flex-col text-xs">
        <div className="flex justify-between pr-12">
            <span>Minimum:</span>
            <span>{min} {unitCode}</span>
        </div>
        <div className="flex justify-between pr-12">
            <span>Maximum:</span>
            <span>{max} {unitCode}</span>
        </div>
    </div>
)

export default TradeLimits
