import React from 'react'
import State from '../../../util/State'
import AssetCard from './AssetCard'
import NewOrderModalWithState from '../new-order/NewOrderModalWithState'
import Modal from '../../../util/Modal'

const INITIAL_STATE = {
    isOpen: false,
    side: null,
    unitCode: null,
    currencyCode: null,
    initialSpotPrice: null,
    dismissIsDisabled: null,
}

const AssetCardWithModal = ({
    asset,
    canBuy,
    marketData,
    unitCode,
    currencyCode,
    currencySymbol,
    availableUnits,
    productVariationId,
    availableProductVariations,
    onChangeProductVariation,
    maxTradeLimit,
    minTradeLimit,
    onToggleKarats,
    isShowingKarats,
}) => (
    <State initialState={INITIAL_STATE}>
        {({ state, setState }) => (
            <>
                <AssetCard
                    marketData={marketData}
                    name={asset.name}
                    code={asset.code}
                    canBuy={canBuy}
                    unitCode={unitCode}
                    currencyCode={currencyCode}
                    currencySymbol={currencySymbol}
                    onChangeProductVariation={onChangeProductVariation}
                    productVariationId={productVariationId}
                    availableProductVariations={availableProductVariations}
                    onClickBuy={() => setState({
                        isOpen: true,
                        side: 'BUY',
                        initialSpotPrice: marketData.buyPrice,
                        currencyCode,
                        unitCode,
                        currencyConversion: marketData.currencyConversion
                    })}
                    onToggleKarats={onToggleKarats}
                    isShowingKarats={isShowingKarats}
                />

                <Modal
                    aria-labelledby="Create new order"
                    isOpen={state.isOpen}
                    dismissIsDisabled={state.dismissIsDisabled}
                    onDismiss={() => setState(INITIAL_STATE)}
                >
                    <NewOrderModalWithState
                        initialSpotPrice={state.initialSpotPrice}
                        currencyConversion={state.currencyConversion}
                        side={state.side}
                        asset={asset}
                        currencyCode={currencyCode}
                        unitCode={unitCode}
                        orderRequest={state.orderRequest}
                        onToggleDisableDismiss={(dismissIsDisabled) => setState({ dismissIsDisabled })}
                        maxTradeLimit={maxTradeLimit}
                        minTradeLimit={minTradeLimit}
                        availableUnits={availableUnits}
                        productVariationId={productVariationId}
                        onDismiss={() => setState(INITIAL_STATE)}
                    />
                </Modal>
            </>
        )}
    </State>
)

export default AssetCardWithModal
