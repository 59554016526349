import React from 'react'
import PageError from '../../layout/PageError'
import useFetchTradePageData from './useFetchTradePageData'
import TradePage from './TradePage'

const TradePageWithState = () => {
    const {
        error,
        isFetching,
        myCustomerAssets,
    } = useFetchTradePageData()

    if (typeof error !== 'undefined') {
        return (
            <PageError
                activeMenuItem="trade"
                error={error}
            />
        )
    }
    return (
        <TradePage
            isFetching={isFetching}
            myCustomerAssets={myCustomerAssets}
        />
    )
}

export default TradePageWithState
