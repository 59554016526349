import React from 'react'
import MainCentered from '../../layout/MainCentered'
import UnauthorizedFormWrapper from '../../layout/UnauthorizedFormWrapper'
import LoginPhoneForm from './LoginPhoneForm'

const LoginPhonePage = ({
    formState,
    isSubmitting,
    onSubmit,
}) => (
    <MainCentered>
        <UnauthorizedFormWrapper>
            <div className="text-center">
                <h3>Login Phone</h3>
                <p>
                    {/* eslint-disable-next-line max-len */}
                    We will send you a one time password for you to login with. Please provide your phone number.
                </p>
            </div>
            <LoginPhoneForm
                formState={formState}
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
            />
        </UnauthorizedFormWrapper>
    </MainCentered>
)

export default LoginPhonePage
