import React from 'react'
import posed, { PoseGroup } from 'react-pose'
import clsx from 'clsx'

const AnnimatedMessage = posed.div({
    enter: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
})

const InputGroup = ({
    className,
    label,
    error,
    children,
}) => {
    const hasLabel = typeof label !== 'undefined'
    const hasError = typeof error !== 'undefined' && error !== null && error.length > 0

    return (
        <div className={
            clsx(
                'mb-4',
                hasError && 'text-red-500',
                className,
            )
        }
        >
            {hasLabel && (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label>{label}</label>
            )}
            {children}
            <PoseGroup>
                {hasError && error.map((e, index) => {
                    if (e !== null) {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <AnnimatedMessage key={index}>
                                <small className="block italic">
                                    {e}
                                </small>
                            </AnnimatedMessage>
                        )
                    }
                    return e
                })}
            </PoseGroup>
        </div>
    )
}

export default InputGroup
