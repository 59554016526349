import React, { Component } from 'react'

class FocusDidMount extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef()
    }

    componentDidMount() {
        if (this.ref.current !== null) {
            this.ref.current.focus()
        }
    }

    render() {
        const { children } = this.props
        return children(this.ref)
    }
}

export default FocusDidMount
