import { createBrowserHistory as createHistory } from 'history'

let history = null

export default function getHistory() {
    if (history === null) {
        history = createHistory()
    }
    return history
}
