import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import useAppState from '../../hooks/useAppState'
import useModalState from '../../hooks/useModalState'
import useNotifications from '../../hooks/useNotifications'
import updateMyCustomer from '../../../api/updateMyCustomer'
import { delay } from '../../../util/index'
import useUpdateSettingsForm from './useUpdateSettingsForm'
import SettingsPage from './SettingsPage'
import UpdatePinModalWithState from './new-pin/UpdatePinModalWithState'

const SettingsPageWithState = () => {
    const [isLoggingOut, setIsLoggingOut] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const updatePinModalState = useModalState()
    const apolloClient = useApolloClient()
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    const {
        currentUser,
        logout,
        refresh,
    } = useAppState()
    const formState = useUpdateSettingsForm(currentUser)

    return (
        <>
            <SettingsPage
                currentUser={currentUser}
                formState={formState}
                isSubmitting={isSubmitting}
                isLoggingOut={isLoggingOut}
                onOpenNewPinModal={() => updatePinModalState.open()}
                onSaveCustomer={async (e) => {
                    e.preventDefault()
                    const isValid = formState.validate()
                    if (isValid) {
                        try {
                            setIsSubmitting(true)
                            await updateMyCustomer(apolloClient, formState.valuesToInput())
                            setIsSubmitting(false)
                            await refresh()
                            dispatchSuccess({
                                message: 'Your settings were successfully updated.',
                            })
                        } catch (error) {
                            setIsSubmitting(false)
                            dispatchGraphqlError(error)
                        }
                    }
                }}
                onLogout={async () => {
                    setIsLoggingOut(true)
                    await delay(2000)
                    logout()
                }}
            />
            {updatePinModalState.isOpen && (
                <UpdatePinModalWithState
                    isVisible={updatePinModalState.isOpen}
                    closeModal={() => updatePinModalState.close()}
                />
            )}
        </>
    )
}

export default SettingsPageWithState
