import React from 'react'
import { ApolloProvider } from '@apollo/client'
import Notifications from './util/Notifications'
import AppStateProvider from './providers/AppStateProvider'
import NotificationsProvider from './providers/NotificationsProvider'

const AppProviders = ({
    appState,
    apolloClient,
    notificationDispatcher,
    children,
}) => (
    <AppStateProvider value={appState}>
        <ApolloProvider client={apolloClient}>
            <NotificationsProvider dispatcher={notificationDispatcher}>
                {children}
                <Notifications />
            </NotificationsProvider>
        </ApolloProvider>
    </AppStateProvider>
)

export default AppProviders
