import React, { useState } from 'react'
import { useApolloClient } from '@apollo/client'
import * as routes from '../../../routes/index'
import getHistory from '../../../routes/getHistory'
import requestForEmailLogin from '../../../api/requestForEmailLogin'
import useNotifications from '../../hooks/useNotifications'
import useLoginEmailForm from './useLoginEmailForm'
import LoginEmailPage from './LoginEmailPage'

const LoginEmailPageWithState = () => {
    const { dispatchGraphqlError } = useNotifications()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const apolloClient = useApolloClient()
    const formState = useLoginEmailForm()

    return (
        <LoginEmailPage
            formState={formState}
            isSubmitting={isSubmitting}
            onSubmit={async (e) => {
                e.preventDefault()
                const isValid = formState.validate()
                if (isValid) {
                    try {
                        setIsSubmitting(true)
                        await requestForEmailLogin(apolloClient, formState.values)

                        const history = getHistory()
                        history.push({
                            pathname: routes.loginPassword(),
                            search: `?email=${encodeURIComponent(formState.values.email)}`,
                        })
                    } catch (err) {
                        setIsSubmitting(false)
                        dispatchGraphqlError(err)
                    }
                }
            }}
        />
    )
}

export default LoginEmailPageWithState
