import React from 'react'
import formatDistance from 'date-fns/formatDistance'
import Tag from '../../util/Tag'
import CurrencyValue from '../../util/CurrencyValue'
import FeatherIcon from '../../util/FeatherIcon'
import DropdownMenu from '../../util/DropdownMenu'
import DropdownMenuItem from '../../util/DropdownMenuItem'
import Tooltip from '../../util/Tooltip'
import { SIDE_BUY } from '../../../util/constants'

const OrderListItem = ({
    currentUserId,
    order,
    onEditOrder,
}) => {
    const {
        id,
        uid,
        createdAt,
        side,
        quantityInUnit,
        quantityUnitCode,
        price,
        notes,
        priceUnitCode,
        totalPrice,
        currencyCode,
        asset,
        user,
    } = order
    const isBuy = side === SIDE_BUY
    const createdAtTime = new Date(createdAt).getTime()

    return (
        <li
            key={uid}
            className="flex items-center justify-center mb-2"
        >
            <div className="flex-2 justify-start">
                # {id}
            </div>
            <div className="flex-3">
                <div className="font-medium text-black">
                    {(() => {
                        if (currentUserId === user.id) {
                            return 'You'
                        }
                        if (user.role.id !== 4) {
                            return 'Gunvor Trading Desk'
                        }
                        return user.fullName
                    })()}
                </div>
                <div className="text-sm">
                    {formatDistance(createdAtTime, new Date().getTime())}
                    {' '}
                    ago
                </div>
            </div>
            <div className="flex-1">
                <div className="font-medium text-primary">
                    {asset.code}
                </div>
            </div>
            <div className="flex-1">
                <div className="font-medium text-primary">
                    {currencyCode}
                </div>
            </div>
            <div className="flex-1 text-center">
                <Tag
                    className="text-white"
                    isBlue={isBuy}
                >
                    {side}
                </Tag>
            </div>
            <div className="flex-5  text-right">
                <h5 className="inline">
                    <CurrencyValue
                        isNegative={isBuy}
                        value={totalPrice}
                        currencyCode={currencyCode}
                    />
                </h5>
                <div className="text-sm">
                    {quantityInUnit}{quantityUnitCode}
                    {' '}
                    of
                    {' '}
                    {asset.name.toLowerCase()}
                    {' '}
                    at
                    {' '}
                    <CurrencyValue
                        value={price}
                        currencyCode={currencyCode}
                    />
                    /
                    {priceUnitCode}
                </div>
            </div>
            <div className="flex-1 text-center">
                {notes !== null && (
                    <Tooltip
                        position="top"
                        inline="false"
                        content={notes}
                    >
                        <FeatherIcon
                            name="comment"
                        />
                    </Tooltip>
                )}
            </div>
            <DropdownMenu
                isInline={false}
                position="bottom-end"
                content={({ close }) => (
                    <>
                        <DropdownMenuItem
                            iconName="edit"
                            onClick={() => {
                                onEditOrder(order)
                                close()
                            }}
                        >
                            Edit order
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            iconName="fileText"
                            onClick={close}
                        >
                            <a
                                href={order.receitUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Download receipt
                            </a>
                        </DropdownMenuItem>
                    </>
                )}
            >
                <button
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="true"
                    className="btn-icon border-transparent"
                >
                    <FeatherIcon
                        name="more"
                        size={24}
                    />
                </button>
            </DropdownMenu>
        </li>
    )
}

export default OrderListItem
