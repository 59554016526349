import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import * as routes from '../routes'
import { INITIAL_DATA_QUERY } from '../api/getInitialData'
import LoginPage from './pages/login/LoginPage'
import LoginEmailPageWithState from './pages/login-email/LoginEmailPageWithState'
import LoginPhonePageWithState from './pages/login-phone/LoginPhonePageWithState'
import LoginPasswordPageWithState from './pages/login-password/LoginPasswordPageWithState'
import TradePageWithState from './pages/trade/TradePageWithState'
import HistoryPageWithState from './pages/history/HistoryPageWithState'
import SettingsPageWithState from './pages/settings/SettingsPageWithState'
import OnboardingPageWithState from './pages/onboarding/OnboardingPageWithState'

const AppRouter = ({
    history,
    isAuthenticated,
    currentUser,
}) => {
    if (!isAuthenticated) {
        return (
            <Router history={history}>
                <Switch>
                    <Route
                        exact
                        path={routes.login()}
                        component={LoginPage}
                    />
                    <Route
                        exact
                        path={routes.loginEmail()}
                        component={LoginEmailPageWithState}
                    />
                    <Route
                        exact
                        path={routes.loginPhone()}
                        component={LoginPhonePageWithState}
                    />
                    <Route
                        exact
                        path={routes.loginPassword()}
                        component={LoginPasswordPageWithState}
                    />
                    <Route path="/" component={LoginPage} />
                </Switch>
            </Router>
        )
    }
    if (!currentUser.hasCompletedOnboarding) {
        return <OnboardingPageWithState queriesToRefetch={[{ query: INITIAL_DATA_QUERY }]} />
    }
    return (
        <Router history={history}>
            <Switch>
                <Route exact path={routes.home()} component={TradePageWithState} />
                <Route exact path={routes.trade()} component={TradePageWithState} />
                <Route exact path={routes.history()} component={HistoryPageWithState} />
                <Route exact path={routes.settings()} component={SettingsPageWithState} />
                <Route path="/" component={TradePageWithState} />
            </Switch>
        </Router>
    )
}

export default AppRouter
